import { Box, Flex, Grid, GridItem, Icon } from '@chakra-ui/react';
import { FC } from 'react';
import { ImageFragment, ImageStyleFragment } from '../../generated/types';
import Link from 'next/link';
import { IoChevronForward } from '@react-icons/all-files/io5/IoChevronForward';
import { Image } from '../Common/Image';
import { HTMLText } from '../Common/HTMLText';

interface TeaserListProps {
  title: string;
  url: string;
  image: ImageFragment & {
    imageStyle: ImageStyleFragment;
  };
  text: string;
  layout: string;
}

export const TeaserList: FC<TeaserListProps> = ({
  title,
  url,
  image,
  text,
  layout,
}) => {
  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      gap={{
        base: 0,
        md: 6,
      }}
      width="100%"
    >
      <GridItem
        colSpan={{
          base: 12,
          md: 4,
        }}
      >
        <Box pos="relative" overflow="hidden">
          <Flex justifyContent="flex-end">
            <Link href={url} passHref>
              <a>
                <Image
                  borderTopRadius="sm"
                  imageStyle={image.imageStyle}
                  image={image}
                  hideCopyright
                />
              </a>
            </Link>
          </Flex>
        </Box>
      </GridItem>
      <GridItem
        colSpan={{
          base: 12,
          md: 8,
        }}
        display="flex"
        flexDir="column"
      >
        <Link href={url} passHref>
          <Box
            mt={{
              base: 2,
              md: 0,
            }}
            display="block"
            as="a"
            fontSize="2xl"
            fontWeight="bold"
            mb={{
              base: 0,
              md: 2,
            }}
            color="primary"
            _hover={{
              color: 'primaryDark',
            }}
          >
            {title}
          </Box>
        </Link>
        <HTMLText mt={2} color="gray.600" noOfLines={4} text={text} />
        <Flex alignItems="center" justifyContent="flex-start" mt="auto">
          <Link href={url} passHref>
            <Box as="a" layerStyle="link_button_blue" mt={4}>
              Mehr erfahren
              <Icon ml={1} as={IoChevronForward} />
            </Box>
          </Link>
        </Flex>
      </GridItem>
    </Grid>
  );
};
