import { Box, BoxProps, Img, ImgProps } from '@chakra-ui/react';
import { ImageFragment, ImageStyleFragment } from '../../generated/types';
import { FC, useContext, useMemo } from 'react';
import { PageContext } from '../../context/PageContext';

type ImageProps = ImgProps & {
  imageStyle: ImageStyleFragment;
  image: ImageFragment;
  hideCopyright?: boolean;
  wrapperProps?: BoxProps;
  description?: string | null;
  copyrightInside?: boolean;
};

export const Image: FC<ImageProps> = ({
  imageStyle,
  image,
  hideCopyright,
  wrapperProps,
  description,
  copyrightInside,
  ...props
}) => {
  if (!imageStyle) return null;

  const showCopyright = !hideCopyright && image.copyright;

  return (
    <Box className={`image-${image.id}`}>
      <Box pos="relative" {...wrapperProps}>
        <Img
          htmlWidth={imageStyle.width}
          htmlHeight={imageStyle.height}
          src={imageStyle.url}
          width="100%"
          loading="lazy"
          alt={image.alt || ''}
          {...props}
        />
        {showCopyright && copyrightInside && (
          <Box
            pos="absolute"
            left={0}
            bottom={0}
            bg="rgba(0,0,0, .4)"
            color="white"
            p={1}
            fontSize="xxs"
          >
            Foto © {image.copyright}
          </Box>
        )}
      </Box>
      {description && (
        <Box pt={1} fontSize="md">
          {description}
        </Box>
      )}
      {showCopyright && !copyrightInside && (
        <Box fontSize="xxs">Foto © {image.copyright}</Box>
      )}
    </Box>
  );
};
