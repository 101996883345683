import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';
import parse, {
  attributesToProps,
  domToReact,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';
import { EnhancedLink } from './EnhancedLink';

type HTMLTextProps = BoxProps & {
  text: string;
};

const parserOptions: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element) {
      if (domNode.name === 'a') {
        const attribs = attributesToProps(domNode.attribs);

        return (
          <EnhancedLink href={attribs.href} {...attribs}>
            {domToReact(domNode.children, parserOptions)}
          </EnhancedLink>
        );
      }
    }
  },
};

export const HTMLText: FC<HTMLTextProps> = ({ text, ...props }) => {
  const parsedHtml = parse(text, parserOptions);

  return (
    <Box
      __css={{
        p: {
          mb: 4,
        },
        'ol, ul': {
          ml: 5,
        },
        a: {
          textDecoration: 'underline',
        },
      }}
      {...props}
    >
      {parsedHtml}
    </Box>
  );
};
