import { FC } from 'react';
import Link from 'next/link';
import { Box, BoxProps } from '@chakra-ui/react';

type EnhancedLinkProps = BoxProps & {
  href: string;
};

export const EnhancedLink: FC<EnhancedLinkProps> = ({
  href,
  children,
  ...props
}) => {
  const isExternal = href.startsWith('http');
  const InnerLink = (
    <Box
      as="a"
      target={isExternal ? '_blank' : undefined}
      href={isExternal ? href : undefined}
      {...props}
    >
      {children}
    </Box>
  );

  return isExternal ? (
    InnerLink
  ) : (
    <Link href={href} passHref>
      {InnerLink}
    </Link>
  );
};
